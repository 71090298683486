.indice {
	top: 88px;
	right: 13px;
	position: fixed;
	width: 25vw;
	height: calc(100vh - 88px);
    overflow-y: scroll;
    
}
.indice::-webkit-scrollbar{
    display: none;
}
div.animacion_container {
    background-image: url(https://storage.googleapis.com/agl-01/wedrim-landing-assets/Trazado%201.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 40vh;
    margin-top: 5%;
    padding-bottom: 10%;
}

div.contenido {
    display: flex;
    height: 50vh;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 0 5% 0;
}

div.animacion_container video.img_principal {
    width: 90%;
    margin-left: 5%;
    border-radius: 2%;
}

div.contenido .contenido_imagenes{
    display: flex;
    width: 35%;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    margin-left: 10%;
}

div.contenido .contenido_imagenes img.wedrim_img {
    width: 100%;
}

div.contenido .contenido_imagenes img {
    max-width: 50%;
}

div.contenido .contenido_imagenes a {
    min-width: 60%;
}

/* div.contenido .contenido_imagenes a.img_wedrim {
    max-width: 100%;
}

div.contenido .contenido_imagenes a.img_cse {
    max-width: 100%;
}

div.contenido .contenido_imagenes a.img_ogi {
    max-width: 100%;
}
div.contenido .contenido_imagenes a.img_acorla {
    max-width: 100%;
} */





div.animacion_container .contenido .contenido_texto{
    display: flex;
    width: 60%;
    justify-content: center;
}

div.animacion_container .contenido .contenido_texto p {
    /* font-family: "Roboto"; */
    font-size: 23px;
    font-weight: 500;
    color: #ffffff;
    width: 80%;
    text-align: center;
    padding-bottom: 5%;
    padding-top: 5%;
}

div.contenido button {
    /* Font-family: "Roboto"; */
    font-size: 18px;
    font-weight: 500;
    color: #1b346b;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #ffffff;
    cursor: pointer;
}

div.contenido button:hover {
    /* Font-family: "Roboto"; */
    font-size: 18px;
    font-weight: 500;
    color: #1b346b;
    background-color: #ffffff;
    border-style: solid;
    border-radius: 100px 100px 100px 100px;
    padding-left: 45px;
    padding-right: 45px;
    transition: 0.2s;
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #ffffff;
    cursor: pointer;
}